// Excel PMT function
function pmt(rate, numPayments, presentValue) {
  const futureValue = 0;
  const type = 0;
  const vPow = Math.pow(1 + rate, numPayments);
  const vT = type === 0 ? rate : rate / (1 + rate);

  if (vPow - 1 === 0) return presentValue / numPayments;
  else return ((vPow * presentValue - futureValue) / (vPow - 1)) * vT;
}

export function calculateInstalmentAmount(
  loanAmount,
  interest,
  termOfLoan,
  numberOfInstalments,
  instalmentFee
) {
  const rate = interest / 100 / numberOfInstalments;
  const numPayments = termOfLoan * numberOfInstalments;
  const presentValue = -loanAmount;
  const instalmentAmount = -pmt(rate, numPayments, presentValue);

  // Magic number to balance out
  const magic = 10.0;

  if (numPayments === 0) {
    return loanAmount + instalmentFee + magic;
  } else {
    return instalmentAmount + instalmentFee + magic;
  }
}
