import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useId } from "@reach/auto-id";

import { Button } from "dnb-ui-lib/components";

import styles from "./styles.module.css";

function Tooltip(props) {
  const [isOpen, setOpen] = useState(false);
  const uid = useId();
  const id = `tooltip-${uid}`;

  const dropdown = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      const clickOutside = !(
        dropdown.current === event.target ||
        !!(dropdown.current.compareDocumentPosition(event.target) & 16)
      );

      if (clickOutside && isOpen) {
        setOpen(false);
      }
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.metaKey || e.ctrlKey) {
        return;
      }

      // Escape
      if (e.keyCode === 27) {
        setOpen(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  return (
    <div className={styles.tooltip}>
      <Button
        class="dnb-button__tooltip dnb-button--hide-text"
        variant="secondary"
        icon="question"
        size="medium"
        title={props.title}
        text={props.title}
        aria-describedby={`${id}`}
        onClick={() => setOpen(!isOpen)}
      />

      <div id={id} ref={dropdown} role="tooltip">
        {isOpen ? (
          <div id={`${id}-content`} className={styles.body}>
            {props.children}
          </div>
        ) : null}
      </div>
    </div>
  );
}

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
