import React from "react";
import cx from "classnames";

import styles from "./styles.module.css";

function Badge({ as: Component = "span", className, children, ...props }) {
  return (
    <Component className={cx(styles.default, className)} {...props}>
      {children}
    </Component>
  );
}

export default Badge;
