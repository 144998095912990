class SearchParamsURL {
  constructor(search) {
    this._search = search;
    this._searchParams = {};
    if (this._search) {
      const searchParams = this._search.substr(1).split("&");
      searchParams.forEach(pair => {
        const [key, value] = pair.split("=");
        this._searchParams[decodeURIComponent(key)] = decodeURIComponent(value);
      });
    }
  }

  has(key) {
    return this._searchParams[key] !== undefined;
  }

  get(key) {
    return this._searchParams[key] ? this._searchParams[key] : null;
  }

  toString() {
    return this._search;
  }

  append(key, value) {
    this._searchParams[key] = value;
  }

  getObject() {
    return this._searchParams;
  }
}

export default SearchParamsURL;
