import React from "react";
import { InputMasked, Button } from "dnb-ui-lib/components";
import { format } from "dnb-ui-lib/components/Number";
import { add as Add, subtract as Subtract } from "dnb-ui-lib/icons";

import styles from "./styles.module.css";

function Stepper({ onChange, increment, ...props }) {
  const calculateTotal = total => (total >= 0 ? total : 0);

  const vary = ({ type }) => {
    switch (type) {
      case "decrease":
        return onChange({
          [props.id]: calculateTotal(props.value - increment),
        });
      case "increase":
        return onChange({
          [props.id]: calculateTotal(props.value + increment),
        });
      default:
        break;
    }
  };

  const handleOnKeyDown = ({ event }) => {
    // up arrow, page up
    const up = [38, 33];
    // down arrow, page down
    const down = [40, 34];

    if (up.includes(event.keyCode)) {
      vary({ type: "increase" });
    }

    if (down.includes(event.keyCode)) {
      vary({ type: "decrease" });
    }
  };

  const isCurrency = props.currency_mask === "kr";

  const formatValue = format(props.value, {
    locale: "nb-NO",
    currency: isCurrency,
    returnAria: true,
  });

  return (
    <div
      className={styles.root}
      role="group"
      aria-labelledby={props.described_by}
    >
      <Button
        on_click={() => vary({ type: "decrease" })}
        className="btn-primary--green"
        icon={Subtract}
        variant="primary"
        title="minsk verdi"
        aria-label="minsk verdi"
        aria-describedby={props.described_by}
      />
      <div>
        <InputMasked
          {...props}
          on_change={val =>
            onChange({ [props.id]: parseInt(val.cleaned_value) })
          }
          onKeyDown={handleOnKeyDown}
          value={props.value}
          input_class="form-input"
          aria-hidden="true"
          size="large"
          top
          left
          right
          bottom
        />
      </div>

      <div className="visually-hidden">
        <span>
          {props.label_text} er {formatValue.aria}{" "}
          {!isCurrency && props.currency_mask}
        </span>
      </div>

      <Button
        on_click={() => vary({ type: "increase" })}
        className="btn-primary--green"
        icon={Add}
        variant="primary"
        title="øk verdi"
        aria-label="øk verdi"
        aria-describedby={props.described_by}
      />
    </div>
  );
}

export default Stepper;
