import React, { useState } from "react";
import {
  FormRow,
  FormSet,
  FormLabel,
  Space,
  Section,
  Button,
  Number,
} from "dnb-ui-lib/components";
import { H1, P, Link } from "dnb-ui-lib/elements";
import { format } from "dnb-ui-lib/components/Number";

import Provider from "dnb-ui-lib/shared/Provider";
import Stepper from "./components/Stepper";
import Badge from "./components/Badge";
import Tooltip from "./components/Tooltip";

import { calculateInstalmentAmount } from "./utils/calculator";
import SearchParamsURL from "./utils/SearchParamsURL";

import logo from "./images/dnb.png";
import data from "./callback.json";

const translations = {
  title: "Søk om boliglån fra DNB",
  costPerMonth: "Kostnader per måned",
  totalLoanCost: "Total lånekostnad",
  searchForLoan: "Søk om boliglån",
  purchaseAmount: "Kjøpesum",
  years: "Nedbetalingstid",
  loanAmount: "Lånebeløp",
  footer:
    "BLU, nominal interest rate from 1.95%, effective interest from 2.04%, kr. 2,000,000 r / 25 years. Cost: kr. 546 400, total: kr. 2 546 400.",
};

const numberFormatOptions = {
  locale: "nb-NO",
  currency: true,
  returnAria: true,
};

function App() {
  const params = new SearchParamsURL(window.location.search);
  const { numberOfInstalments, percentsign, segment } = data;
  const tmpSegment = segment[0];

  // example: ?pris=3500000&tid=25
  const price = parseInt(params.get("pris"));
  const years = parseInt(params.get("tid"));

  const [values, setValues] = useState({
    purchaseAmount: price || 2000000,
    years: years || 20,
    loanAmount: (price / 100) * tmpSegment.costs.maxLoanPercentage || 1000000,
  });

  const [fieldChanged, setFieldChanged] = useState();

  const handleValueChange = obj => {
    const key = Object.keys(obj)[0];
    setFieldChanged(key);

    // safeguard
    if (isNaN(obj[key])) {
      obj[key] = 0;
    }
    setValues({ ...values, ...obj });
  };

  const interestRate = tmpSegment.interestArray.find(interest => {
    if (interest.to !== 0) {
      return (
        values.loanAmount >= interest.from && values.loanAmount <= interest.to
      );
    } else {
      return values.loanAmount >= interest.from;
    }
  });

  const rate = parseFloat(interestRate.rate).toFixed(2);

  const monthlyPayment = Math.round(
    calculateInstalmentAmount(
      values.loanAmount,
      rate,
      values.years,
      numberOfInstalments,
      tmpSegment.costs.monthlyFee
    )
  );

  const totalLoanCost = Math.round(
    monthlyPayment * (values.years * numberOfInstalments)
  );

  return (
    <Provider
      space={{
        top: "x-small",
        bottom: "x-small",
      }}
      locale="nb-NO"
    >
      <main className="main">
        <section>
          <div className="content">
            <Space
              className="title-space"
              left="0"
              bottom="4.375"
              right="0"
              top="0"
            >
              <H1 className="oceanGreen title" style_type="small">
                {translations.title}
              </H1>
            </Space>
            <Space>
              <P style_type="medium-size">{translations.costPerMonth}</P>
            </Space>
            <Space>
              <P
                className="seaGreen monthly-payment"
                style_type="xx-large-size"
              >
                <Number currency value={monthlyPayment} />
              </P>
            </Space>
            <Space>
              <P style_type="x-small">
                {translations.totalLoanCost} &nbsp;
                <Number currency value={totalLoanCost} />
              </P>
            </Space>
            <Space>
              <Badge>
                {rate}
                {percentsign}
              </Badge>{" "}
              <P
                is="span"
                style_type="x-small medium"
                left="x-small"
                right="x-small"
                aria-label="nominel rente eller effektiv rente"
              >
                nom. rente – eff.rente
              </P>
              <Tooltip title="Har du en spørsmål?">
                <p>
                  Summen du kjøper boligen for, uten omkostninger.{" "}
                  <Link
                    href="https://samsolgt.no"
                    className="dnb-anchor dnb-anchor--contrast"
                  >
                    Les mer her
                  </Link>
                </p>
              </Tooltip>
            </Space>
            <Space top="2.5" bottom="2.5" className="cta-search-loan">
              <Button href="https://www.dnb.no/privat/laan/boliglaan.html">
                {translations.searchForLoan}
              </Button>
            </Space>
          </div>
          <div className="content content__aside">
            <Section
              is="div"
              className="dnb-section--padding dnb-section--no-box-shadow dnb-section__calculator"
              style_type="white"
            >
              <FormSet role="form">
                <div className="form-space">
                  <div>
                    <FormLabel
                      className="dnb-label--green"
                      for_id="purchaseAmount"
                      id="purchaseAmountLabel"
                    >
                      {translations.purchaseAmount}
                    </FormLabel>
                    <Tooltip title="Har du en spørsmål?">
                      <p>
                        Summen du kjøper boligen for, uten omkostninger.{" "}
                        <Link
                          href="https://samsolgt.no"
                          className="dnb-anchor dnb-anchor--contrast"
                        >
                          Les mer her
                        </Link>
                      </p>
                    </Tooltip>
                  </div>
                  <FormRow>
                    <Stepper
                      align="center"
                      onChange={handleValueChange}
                      increment={100000}
                      value={values.purchaseAmount}
                      id="purchaseAmount"
                      currency_mask="kr"
                      label_text={translations.purchaseAmount}
                      described_by="purchaseAmountLabel"
                    />
                  </FormRow>
                </div>
                <div className="form-space">
                  <div>
                    <FormLabel
                      className="dnb-label--green"
                      for_id="years"
                      id="paydownTimeLabel"
                    >
                      {translations.years}
                    </FormLabel>
                    <Tooltip title="Har du en spørsmål?">
                      <p>
                        Summen du kjøper boligen for, uten omkostninger.{" "}
                        <Link
                          href="https://samsolgt.no"
                          className="dnb-anchor dnb-anchor--contrast"
                        >
                          Les mer her
                        </Link>
                      </p>
                    </Tooltip>
                  </div>
                  <FormRow>
                    <Stepper
                      align="center"
                      onChange={handleValueChange}
                      increment={1}
                      value={values.years}
                      id="years"
                      label_text={translations.years}
                      currency_mask="år"
                      described_by="paydownTimeLabel"
                    />
                  </FormRow>
                </div>
                <div className="form-space">
                  <div>
                    <FormLabel
                      className="dnb-label--green"
                      for_id="loanAmount"
                      id="loanAmountLabel"
                    >
                      {translations.loanAmount}
                    </FormLabel>
                    <Tooltip title="Har du en spørsmål?">
                      <p>
                        Summen du kjøper boligen for, uten omkostninger.{" "}
                        <Link
                          href="https://samsolgt.no"
                          className="dnb-anchor dnb-anchor--contrast"
                        >
                          Les mer her
                        </Link>
                      </p>
                    </Tooltip>
                  </div>
                  <FormRow>
                    <Stepper
                      align="center"
                      onChange={handleValueChange}
                      increment={100000}
                      value={values.loanAmount}
                      id="loanAmount"
                      currency_mask="kr"
                      label_text={translations.loanAmount}
                      described_by="loanAmountLabel"
                    />
                  </FormRow>
                </div>
              </FormSet>
              <div
                className="visually-hidden"
                aria-live="polite"
                aria-atomic="true"
                aria-relevant="all"
              >
                Lån har endret fordi {translations[fieldChanged]} endringer til{" "}
                {fieldChanged === "years"
                  ? values[fieldChanged] + " år"
                  : format(values[fieldChanged], numberFormatOptions).aria}{" "}
                Kostnader per måned kr{" "}
                {format(monthlyPayment, numberFormatOptions).aria} og Total
                lånekostnad kr {format(totalLoanCost, numberFormatOptions).aria}
                med {rate}
                {percentsign} nominel rente – effektiv rente
              </div>
            </Section>
          </div>
        </section>
        <section>
          <div className="content">
            <Space top="2" bottom="2">
              <P className="oceanGreen" style_type="x-small">
                {translations.footer}
              </P>
            </Space>
          </div>
          <div className="content content__footer">
            <img src={logo} className="logo" alt="DNB" />
          </div>
        </section>
      </main>
    </Provider>
  );
}

export default App;
